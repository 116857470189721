<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";

  export default {
    page: {title: "EINSTUDIO-Assessments",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader
    },data() {
      return {
        title: "Assessments",
        items: [{text: "Home",href:"#"}, {text: "Assessments",active: true}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],fileArray:[],name:"",explanation:"",syllabusfile:"",recordId:""
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loadData();
      },methods: {
         addData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-file-form"));

         formToPost.append("status",true)
         axios.post(`${this.baseUrl}/Lesson/lessonAssessments/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.hideModal('modal-lg');
          this.loadData();
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){
          let url= this.baseUrl+"/Lesson/LessonAssessments/GetAll";
          axios.get(url,{
            headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.fileArray= response.data;
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY HH:mm')
          }
      },deleteData(id){
            let loader= this.$loading.show();
            axios.delete(`${this.baseUrl}/Lesson/lessonAssessments/Delete/`+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
      },editInitialData(id){
            axios.get(this.baseUrl+"/Lesson/lessonAssessments/GetById/"+id,{
                headers:{Authorization: `Bearer ${this.user.jwt}`}
            }).then((response)=>{
    
                this.name=response.data.name;
                this.explanation=response.data.explanation;
                this.recordId=response.data.id;
                this.syllabusfile= response.data.fileUrl;
            }).catch((error)=>{
            const html ="<small>"+ error.response.data.message+"</small>";
            this.$swal("",html,"error");
            })
      },editData(){
            let loader = this.$loading.show();
            var formToPost = new FormData(document.getElementById("edit-file-form"));
            formToPost.append("status",true)
            formToPost.append("id",this.recordId)
            axios.put(`${this.baseUrl}/Lesson/lessonAssessments/Set`, formToPost, {
            headers: { Authorization: `Bearer ${this.user.jwt}`}
            })
            .then((response) => 
            {
                loader.hide();
                this.$swal("",response.data.message, "success");
                this.hideModal('modal-edit');
                this.loadData();
            })
            .catch((error) => 
            {
                loader.hide();
                var html="";
                if(error.response.data.length>0)
                {
                for (let index = 0; index < error.response.data.length; index++) 
                {
                    html +="<p>"+ error.response.data[index].message+"</p>";
                }
                }
                else
                {
                html ="<p>"+ error.response.data.message+"</p>";
                }
                this.$swal("",html,"error");
            });
      },DeleteConfirm(id){
            this.$swal({ 
            title: "WARNING",
            text: "Are you sure? This data will be delete.",
            //type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes",
            cancelButtonText:"No",
            }).then((result) => {
                if (result.value) 
                {
                this.deleteData(id)
                }
            });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3" v-if="this.user.rol=='admin'">
        <div class="col-lg-12">
          <a type="button" id="add-modal"  v-b-modal.modal-lg  class="btn btn-success"><i class="fa fa-plus"></i> ADD FILE</a>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-12" v-for="(item, index) in fileArray" :key="index">
            <iframe style="width: 1300px; height: 800px;" :src="fileUrl+'/files/images/lesson/'+item.fileUrl" height="240" width="320" frameborder="0"></iframe>
        </div> -->
       
      <div class="col-lg-3 col-xl-3 col-md-12 col-sm-12" v-for="(item, index) in fileArray" :key="index">
            <div class="card">
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                          <p><i class="fa fa-file"> </i> {{item.name}}</p>
                            <img  :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <a v-b-tooltip.hover title="Open" class="btn btn-sm btn-info mr-1" :href="(fileUrl+'/files/images/lesson/'+item.fileUrl)" target="_blank"><i class="fa fa-eye"></i></a>
                        <a type="button" v-b-tooltip.hover title="Edit" v-if="user.rol=='admin'"  class="btn btn-sm btn-warning mr-1" @click="editInitialData(item.id)" v-b-modal.modal-edit ><i class="fa fa-edit"></i></a>
                        <a v-b-tooltip.hover title="Delete" type="button" v-if="user.rol=='admin'"  class="btn btn-sm btn-danger" @click="DeleteConfirm(item.id)"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Assessment Item" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>File Name</label>
                        <input type="text" class="form-control" v-model="name" name="name" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>File (*)</label>
                        <input type="file" class="form-control" name="file" autocomplete="off" accept=".pdf"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" v-model="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE FILE</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-edit" size="lg" title="Edit Assessment Item" title-class="font-18" hide-footer>
        <form @submit.prevent="editData()" id="edit-file-form">
            
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>File Name</label>
                        <input type="text" class="form-control" v-model="name" name="name" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>File</label>
                        <input type="file" class="form-control" name="file" autocomplete="off" accept=".pdf"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" v-model="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE FILE</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>